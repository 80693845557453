import { StarIcon } from '@heroicons/react/20/solid';

const testimonials = [
  '/images/home/testimonials-small/1.jpg',
  '/images/home/testimonials-small/2.png',
  '/images/home/testimonials-small/3.jpg',
  '/images/home/testimonials-small/4.png',
  '/images/home/testimonials-small/5.jpg',
  '/images/home/testimonials-small/6.png',
];

function TestimonialsSmall() {
  return (
    <section className="container-default section-default">
      <h4 className="text-base font-normal text-center mt-0 mb-8 ">
        Trusted by Thousands of Marketers
      </h4>
      <div className="grid grid-cols-2 gap-8 md:grid-cols-6">
        {testimonials.map((testimonial, index) => {
          return (
            <div className="flex flex-col items-center" key={index}>
              <img
                src={testimonial}
                alt="Person"
                className="mb-4 w-10 h-10 object-cover rounded-full border-ui-100 border"
              />
              <div className="flex items-center gap-1">
                <StarIcon className="fill-utility-yellow w-4" />
                <StarIcon className="fill-utility-yellow w-4" />
                <StarIcon className="fill-utility-yellow w-4" />
                <StarIcon className="fill-utility-yellow w-4" />
                <StarIcon className="fill-utility-yellow w-4" />
              </div>
              <div className="mt-2 text-primary-950">5 out of 5</div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default TestimonialsSmall;
